<template>
  <section>
    <el-dialog
      title="数据申请"
      width="480px"
      v-bind="$attrs"
    >
      <div>
        <p>当前积分为{{ score }}, 申请当天数据需{{ price }}积分，24小时内有效</p>
        <p>一旦申请成功无法退还积分，权限多表多端通用</p>
        <p>如当天已购买，请勿重复提交</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="$emit('update:modelValue', false)">取消</el-button>
          <el-button size="small" type="primary" @click="buyData">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { defineComponent, toRefs, ref, getCurrentInstance, computed } from 'vue'
export default defineComponent({
  emits: ['success'],
  setup(props, { attrs, slots, emit }){
    const { proxy } = getCurrentInstance()
    let price=ref(10);  // 所需积分
    let score=computed(()=>proxy.$store.state.userInfo.score)
    
    // 购买数据
    async function buyData(){
      let res=await proxy.$api.buyData()
      if (res) {
        proxy.$message.success({
          message: '申请成功，页面将刷新数据',
          type: 'success',
        })
        $emit('input', false)
        $emit('success')
      }
    }

    return {
      price,
      score,
      buyData,
    }
  },
  
  watch: {
    // 弹框显示
    '$attrs.modelValue'(){
      this.$store.dispatch('getUserInfo', {
        noMessage: {
          noSuccess: 1,
          // noFail: 1,
          noLoading: 1
        }
      })
    }
  }

})

</script>
<style lang='scss' scoped>
</style>