<template>
  <el-dialog
    v-bind="$attrs"
  >
    <header ><span @click="getTapeData">刷新盘口</span></header>
    <div class="market">
      <iframe :src="`https://money.sina.cn/h5chart/apptkchartleju.html?direction=vertical&symbol=${$attrs.code}`" frameborder="0"></iframe>
      <div class="tape" >
        <ul class="seller">
          <li v-for="item in tape.seller" :key="item.label">
            <span>{{item.label}}</span>
            <span>{{item.value}}</span>  
            <span>{{item.count}}</span> 
          </li>
        </ul>
        <ul class="buyer">
          <li v-for="item in tape.buyer" :key="item.label">
            <span>{{item.label}}</span>
            <span>{{item.value}}</span>  
            <span>{{item.count}}</span> 
          </li>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      tape: {
        seller: [
          {
            label: '卖五',
            value: 0,
            count: 0
          },
          {
            label: '卖四',
            value: 0,
            count: 0
          },
          {
            label: '卖三',
            value: 0,
            count: 0
          },
          {
            label: '卖二',
            value: 0,
            count: 0
          },
          {
            label: '卖一',
            value: 0,
            count: 0
          }
        ],
        buyer: [
          {
            label: '买一',
            value: 0,
            count: 0
          },
          {
            label: '买二',
            value: 0,
            count: 0
          },
          {
            label: '买三',
            value: 0,
            count: 0
          },
          {
            label: '买四',
            value: 0,
            count: 0
          },
          {
            label: '买五',
            value: 0,
            count: 0
          }
        ]
      }
    };
  },

  components: {},

  computed: {},

  methods: {
    // 获取盘口数据
    async getTapeData(){
      let res=await this.$api.getTape({
        stock: this.$attrs.code,
        noMessage: {
          noLoading: 1
        }
      })
      if (res) {
        const cache=res.split(',')
        this.tape={
          seller: [
            {
              label: '卖五',
              value: cache[29],
              count: this.getCount(cache[28])
            },
            {
              label: '卖四',
              value: cache[27],
              count: this.getCount(cache[26])
            },
            {
              label: '卖三',
              value: cache[25],
              count: this.getCount(cache[24])
            },
            {
              label: '卖二',
              value: cache[23],
              count: this.getCount(cache[22])
            },
            {
              label: '卖一',
              value: cache[21],
              count: this.getCount(cache[20])
            }
          ],
          buyer: [
            {
              label: '买一',
              value: cache[11],
              count: this.getCount(cache[10])
            },
            {
              label: '买二',
              value: cache[13],
              count: this.getCount(cache[12])
            },
            {
              label: '买三',
              value: cache[15],
              count: this.getCount(cache[14])
            },
            {
              label: '买四',
              value: cache[17],
              count: this.getCount(cache[16])
            },
            {
              label: '买五',
              value: cache[19],
              count: this.getCount(cache[18])
            }
          ]
        }
      }
      // console.log(res)

    },

    // 获取转债正确数量
    getCount(count){
      if (!count) return 0
      if (this.$attrs.title.includes('正股')) return count / 100
      return count / 10
    }
  },

  mounted() {},
  watch: {
    '$attrs.code'(){
      this.getTapeData()
    }
  }
}

</script>
<style lang='scss' scoped>
header{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  cursor: pointer;

  span{
    font-size: 16px;
    color: red;
  }
}
.market{
  display: flex;

  .tape{
    width: 150px;
    height: 100%;
    flex-shrink: 0;  
    padding: 10px;
    box-sizing: border-box;
    ul{
      width: 100%;
      &:first-child{
        margin-bottom: 20px;
      }
      li{
        width: 100%;
        line-height: 1.5;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  iframe{
    width: 100%;
    height: 600px;
  }

}
</style>