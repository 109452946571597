<template>
  <section class="convertible_table">
    <!-- 筛选 -->
    <filters
      v-if="!hiddenFilter" 
      class="filters" 
      :config="filterConfig"
      :params="filterParams"
      @filter="filterFn"
      @more="$emit('more')"
    ></filters>

    <!-- 表格 -->
    <e-table 
      class="e_table"
      ref="table"
      :column="column" 
      :data="data" 
      :headerBtns="headerBtns" 
      :total="isOnlyCollect? collectList.length : allData.length"
      stripe
      lazy
      :row-style="{height: '0'}"
      :cell-style="{padding: '3px'}"
      v-bind="{...$attrs, ...$props}"
      @sort-change="sortChange"
    >
      <template v-slot:header_left v-if="!isOnlyCollect">
        共{{ allData.length }}条数据
        <span class="data_tips">无数据权限时为乱序</span>
        <p class="filter_plates">当前热门板块： 
          <span 
            v-for="(it, ind) in hotPlates" 
            :key="it"
            class="filter_plates_item"
            @click="setHotPlatesData(it)"
          >{{ `${ind && '/' || ''}${it}` }}</span>
        </p>
      </template>
      <template v-slot:header_right>
        <el-checkbox 
          v-if="!isOnlyCollect"
          v-model="autoBuyData"
          @change="autoBuyDataFn"
          style="margin-right: 10px"
        >自动订阅数据</el-checkbox>
        <el-checkbox 
          v-if="!isOnlyCollect"
          v-model="autoRefresh"
          @change="autoRefreshFn"
          style="margin-right: 10px"
        >自刷</el-checkbox>
        <el-checkbox 
          v-if="!isOnlyCollect"
          v-model="hiddenRedeem"
          style="margin-right: 10px"
        >隐藏强赎</el-checkbox>
        <el-input class="search" v-model.lazy="searchText" placeholder="输入代码或者名称搜索" size="small" clearable prefix-icon="el-icon-search"></el-input>
      </template>
    </e-table>

    <!-- 申请数据 -->
    <buy-data 
      v-model="buyData.visible"
      @success="buyDataSuccess"
    ></buy-data>

    <!-- 实时行情 -->
    <market
      v-bind="marketDialog"
      v-model="marketDialog.visible"
    ></market>
  </section>
</template>

<script src="./index.js">
</script>

<style lang="scss" scoped>
.convertible_table{
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .search{
    margin-right: 10px;
  }
  ::v-deep a{
    color: #0000ee;
  }
  ::v-deep .comprehensive_rating{
    color: red;
  }
  ::v-deep .table_row{
    height: 30px;
  }

  ::v-deep .redeem{
    color: red;
  }
  ::v-deep .adjusted{
    color: green;
  }
  ::v-deep .qsxz{
    color: rgb(179, 135, 212);
  }

  .e_table{
    padding-top: 5px;
    flex: 1;
    overflow: hidden;
  }

  .filters{
    margin-bottom: 5px;
  }
}

.data_tips{
  color: red;
  margin: 0 10px;
  // font-weight: bold;
}
.filter_plates{
  margin-top: 3px;
  font-size: 15px;
  .filter_plates_item{
    cursor: pointer;
  }
}
</style>
