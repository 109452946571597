import filters from '@/components/filter/index.vue'
import eTable from '@/components/table/index.vue'
import utils from '@/utils'
import { defineComponent, ref, reactive } from 'vue'
import { mapState, mapGetters } from 'vuex'
import minix from './minix'
import buyData from '@/components/buyData/index.vue'
import market from '@/components/market/index.vue'

const { isLoginStatus, randomTimer } = utils

export default defineComponent({
  mixins: [minix],
  components: {
    'e-table': eTable,
    filters,
    'buy-data': buyData,
    market
  },
  props: {
    // 是否显示选择
    canSelect: {
      type: Boolean,
      default: true
    },
    /*
      multiFactor 默认所有因子表
      multiFactor_v1_1 多因子1.1
      fiveFactor 五因子表
      dbLow  双低表
      power 强势表
    */
    type: {
      type: String,
      default: 'multiFactor'
    },
    height: {
      type: [String, Number],
      default: '0'
    },
    // 只展示自选的
    isOnlyCollect: {
      type: Boolean,
      default: false
    },
    // 是否显示头部筛选
    hiddenFilter: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      isExtend: ref(false),  // 是否拓展表格
      hiddenRedeem: true,  // 隐藏强赎
      autoBuyData: JSON.parse(localStorage.getItem('autoBuyData')) || false,  // 自动购买数据
      autoRefresh: false,  // 自动刷新
      autoRefreshTimer: null,  // 自动刷新计时器
      headerBtns: reactive([
        {
          size: 'small',
          text: '简化/拓展',
          onclick:()=>{
            this.isExtend = !this.isExtend
          }
        },
        {
          size: 'small',
          text: '刷新',
          onclick:()=>{
            this.getData()
          }
        },
        {
          size: 'small',
          text: '申请数据',
          onclick:()=>{
            this.buyData.visible=true
          }
        },
        {
          size: 'small',
          text: '五积分下载数据',
          onclick:()=>{
            if (!utils.isLoginStatus()) return this.$message.error('请先登录')
            this.downloadFile()
          }
        },
      ]),
      searchText: ref(''), // 搜索文本
      // data: reactive([]), // 表格数据
      rawData: [], // 原始数据
      allData: reactive([]),  // 所有表格数据备份
      // result: [], // 备份数据
      levels: reactive({  // 转债等级
        '全部': 0,
        'B-': 1,
        'B': 2,
        'B+': 3,
        'BB-': 4,
        'BB': 5,
        'BB+': 6,
        'BBB-': 7,
        'BBB': 8,
        'BBB+': 9,
        'A-': 10,
        'A': 11,
        'A+': 12,
        'AA-': 13,
        'AA': 14,
        'AA+': 15,
        'AAA': 16
      }),
      maxScrollTop: ref(0),  // 最大滚动高度
      page: ref(1),  // 页码
      size: ref(30), // 单页大小
      timer: ref(null), // 定时器
      filterParams: {}, // 过滤的参数
      collectList: [], // 收藏列表
      
      // 申请数据
      buyData: {
        visible: false,
      },

      // 实时行情
      marketDialog: {
        visible: false,
        code: '',
        title: ''
      }
    }
  },
  computed: {
    ...mapState({
      bondList: state => state.bondList,
      allPlates: state => state.allPlates
    }),
    ...mapGetters([
      'isValidity'
    ]),

    // 搜索转债
    data(){
      let ind=0
      return this.allData.filter(row=>{
        ind++
        // 隐藏强赎【收藏模式无需隐藏】
        if (!this.isOnlyCollect && this.hiddenRedeem && (row['强赎状态'])) {
          ind--
          return false 
        }
        // 仅显示收藏时
        const collectItem=this.collectList.find(i=>i.bond_code==row['转债代码'])
        if (collectItem) {
          // 给列表加上自选价格、自选涨跌幅
          row.bond_price=collectItem.bond_price
          // row.bond_change=collectItem.bond_change
          row.bond_change=(((row['转债价格'] / collectItem.bond_price) - 1) * 100).toFixed(2)
        }
        if (this.isOnlyCollect && !collectItem) return false

        if (Object.keys(this.filterParams).length) {
          let { priceMin, priceMax, 转债余额, volume, 转股溢价率, 评级, 正股涨跌幅, KMin, KMax, sort, MA20乖离, 涨幅对标, 所属版块 } = this.filterParams
          MA20乖离=parseFloat(MA20乖离) / 100

          // 排序 
          if (sort && ind>sort) return false

          // 价格筛选
          if (priceMin && parseFloat(row['转债价格']) < parseFloat(priceMin)) return false
          if (priceMax && parseFloat(row['转债价格']) > parseFloat(priceMax)) return false

          // k值筛选
          if (KMin && parseFloat(row['K']) < parseFloat(KMin)) return false
          if (KMax && parseFloat(row['K']) > parseFloat(KMax)) return false

          // 剩余规模
          if (转债余额 && parseFloat(row['转债余额']) > parseFloat(转债余额)) return false

          // 成交量
          if (volume && parseFloat(row['volume']) < parseFloat(volume)) return false
          // if (svolume && parseFloat(row['svolume']) < parseFloat(svolume)) return false
          // 正股涨跌幅
          if (正股涨跌幅 && parseFloat(row['正股涨跌幅']) < parseFloat(正股涨跌幅)) return false
          if (MA20乖离 && parseFloat(row['MA20乖离']) < parseFloat(MA20乖离)) return false

          // 溢价率
          if (转股溢价率 && parseFloat(row['转股溢价率'])*100 > parseFloat(转股溢价率)) return false
          if (涨幅对标 && parseFloat(row['涨幅对标']) > parseFloat(涨幅对标)) return false

          // 评级
          if (评级 && row['评级'] != 评级) return false

          // 所属版块
          // console.log(所属版块)
          if (所属版块 && 所属版块.length && 所属版块.every(it=>!row['所属版块'] || !row['所属版块'].includes(it))) return false
        }

        // 搜索
        if (this.searchText) {
          return row['转债代码'].includes(this.searchText) || row['债券简称'].includes(this.searchText) || row['正股名称'].includes(this.searchText)
        }
        return true
      })
      .map(row=>({
        ...row,
        isCollect: this.collectList.find(it=>it['bond_code']==row['转债代码'])
      }))
      .slice(0, this.page * this.size)
    },
    // 筛选参数
    filterConfig() {
      return [
        {
          label: '转债价格',
          field: 'price',
          type: 'range',
          unit: '元'
        },
        // {
        //   label: 'K值',
        //   field: 'K',
        //   type: 'range',
        //   unit: ''
        // },
        {
          label: '剩余规模≤',
          field: '转债余额',
          type: 'input',
          unit: '亿元'
        },
        {
          label: '正股涨跌幅≥',
          field: '正股涨跌幅',
          type: 'input',
          unit: '%'
        },
        {
          label: '溢价率≤',
          field: '转股溢价率',
          type: 'input',
          unit: '%'
        },
        {
          label: '排名≤',
          field: 'sort',
          type: 'input',
          unit: ''
        },
        {
          label: 'MA20乖离≥',
          field: 'MA20乖离',
          type: 'input',
          unit: '%'
        },
        {
          label: '涨幅对标≤',
          field: '涨幅对标',
          type: 'input',
          unit: '%'
        },
        {
          label: '评级',
          field: '评级',
          type: 'select',
          unit: '',
          list: [
            {
              label: '全部',
              value: '',
            },
            {
              label: 'AAA',
              value: 'AAA',
            },
            {
              label: 'AA+',
              value: 'AA+',
            },
            {
              label: 'AA',
              value: 'AA',
            },
            {
              label: 'AA-',
              value: 'AA-',
            },
            {
              label: 'A+',
              value: 'A+',
            },
            {
              label: 'A',
              value: 'A',
            },
            {
              label: 'A-',
              value: 'A-',
            },
            {
              label: 'BBB',
              value: 'BBB',
            },
            {
              label: 'BBB-',
              value: 'BBB-',
            },
            {
              label: 'BB',
              value: 'BB',
            },
          ]
        },
        {
          label: '所属版块',
          field: '所属版块',
          type: 'select',
          multiple: true,
          unit: '',
          list: this.allPlates,
          style: 'width: 360px'
        }
      ]
    },
    // 热门板块
    hotPlates(){
      return this.getHotPlates({
        allData: JSON.parse(JSON.stringify(this.rawData)),
        allPlates: this.allPlates && this.allPlates.map(r=>r.label) || []
      })
    }
  },
  methods: {
    // 获取计出数据
    async getData(params={}){
      let res = await this.$api.getBasedata({
        ...params,
        // ...(()=>{
        //   // 收藏时候去掉提醒
        //   if (this.isOnlyCollect) return {
        //     noMessage: {
        //       noSuccess: 1,
        //       noFail: 1
        //     }
        //   }
        //   return {}
        // })()
      })
      if (res) {
        // console.log(res)
        // this.data=res.data.slice(0, 30)
        this.handleData(res)
        !params.noBuy && this.autoBuyDataFn(this.autoBuyData)
      }
    },
    // 处理数据
    handleData(res){
      this.allData=res.data && res.data.length && res.data.reduce((res, cur)=>{
        if (!res.find(i=>i['转债代码']==cur['转债代码'])) res.push(cur)
        return res 
      }, []) || this.allData
      // this.allData=res.data && res.data.length && res.data || this.allData
      const fn={
        multiFactor:()=>{
          this.allData.sort((a, b)=>{
            return b['综合分数'] - a['综合分数']
          })
        },
        'multiFactor_v1_1': ()=>{
          this.allData.sort((a, b)=>{
            return b['多因子V1.1'] - a['多因子V1.1']
          })
        },
        fiveFactor: ()=>{
          this.allData.sort((a, b)=>{
            return b['五因子分数'] - a['五因子分数']
          })
        },
        dbLow: ()=>{
          this.allData.sort((a, b)=>{
            return b['双低分数'] - a['双低分数']
          })
        },
        power: ()=>{
          this.allData.sort((a, b)=>{
            return b['强势分数'] - a['强势分数']
          })
        }
      }
      fn[this.type]()
      this.allData=this.allData.map((i, ind)=>{
        i.index=ind+1
        return i
      })
      this.rawData=[...this.allData]
    },

    // 排序
    sortChange({ column, prop, order }){
      // console.log(order)
      this.allData=this.allData.sort((a, b)=>{
        if (order==='ascending') {
          // 升序
          if (prop === '评级') return this.levels[a[prop]] - this.levels[b[prop]]
          return a[prop] - b[prop]
        } else {
          // 降序
          if (prop === '评级') return this.levels[b[prop]] - this.levels[a[prop]]
          return b[prop] - a[prop]
        }
      })
    },


    // 滚动
    scrollFn() {
      this.$nextTick(() => {
        const dom=document.querySelector('.el-table__body-wrapper')
        // 配置数量，有些屏幕比较高，无法自适应
        setTimeout(()=>{
          const height=dom.offsetHeight
          const size=Math.floor(height / 31)
          this.size=size > 30 && (size + 6) || 30
        })

        // 监听滚动
        dom.addEventListener('scroll', (e) => {
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            const {
              target
            } = e
            const canScrollHeight = target.scrollHeight - target.offsetHeight
            // console.log(e, canScrollHeight, target.scrollTop)
            if (target.scrollTop > this.maxScrollTop) {
              this.maxScrollTop = target.scrollTop
              if (target.scrollTop / canScrollHeight >= 0.8) this.page++
            }

          }, 50);
        })
      })
    },

    // 筛选
    filterFn(params){
      this.filterParams=params
    },

    // 点击收藏
    async clickCollect(row){
      if (!row.isCollect) {
        await this.addBondCollect(row['转债代码'], row['转债价格'])
      } else {
        await this.cancalBondCollect(row['转债代码'])
      }
      this.getBondCollectList()
    },


    // 获取用户收藏列表
    async getBondCollectList(){
      if (!isLoginStatus()) return
      let res=await this.$api.getBondCollectList({
        page: 1,
        limit: 1000
      })
      if (res) {
        this.collectList=res.rows
      }
    },

    // 获取用户收藏列表
    async getBondList(){
      if (!isLoginStatus()) return
      this.$store.dispatch('getBondList')
    },

    // 用户取消转债收藏
    async addBondCollect(id, price){
      return new Promise(async (resolve, reject)=>{
        // const item=this.bondList.find(it=>it.bond_code==id)
        let res=await this.$api.addBondCollect({
          // bond_id: item && item.id || ''
          bond_code: id,
          bond_price: price
        })
        resolve()
      })
    },

    // 用户取消转债收藏
    async cancalBondCollect(id){
      return new Promise(async (resolve, reject)=>{
        // const item=this.bondList.find(it=>it.bond_code==id)
        let res=await this.$api.cancalBondCollect({
          // bond_id: item && item.id || ''
          bond_code: id
        })
        resolve()
      })
    },

    // 保存筛选
    savaFilter(){
      localStorage.setItem(`__${this.$route.name}_filterParams`, JSON.stringify(this.filterParams))
    },
    // 获取筛选参数
    getFilter(){
      const filterParams=localStorage.getItem(`__${this.$route.name}_filterParams`)
      filterParams && (this.filterParams=JSON.parse(filterParams))
    },


    // 申请数据成功
    buyDataSuccess(){
      this.getData()
    },


    // 自刷
    async autoRefreshFn(value){
      if (value) {
        // 开启
        await this.$store.dispatch('getBuyDataStatus')
        if (!this.isValidity) {
          this.$message.error('数据已过期')
          this.autoRefresh=false
          return
        }
        this.autoRefresh=true
        this.autoRefreshTimer=randomTimer({
          context: this,
          timerField: 'autoRefreshTimer',
          max: 5000,
          min: 3000,
          fn: ()=>this.getData({
            noMessage: {
              noLoading: 1
            }
          })
        })
      } else {
        // 关闭
        this.autoRefresh=false
        clearTimeout(this.autoRefreshTimer)
      }
    },


    // 打开实时行情
    openRealTimeQuotes({e, type, row}){
      e.preventDefault()
      if (type=='bond') {
        this.marketDialog.code=row['转债代码']
        this.marketDialog.title=`转债行情 ${row['债券简称']}`
      } else {
        this.marketDialog.code=row['股票代码']
        this.marketDialog.title=`正股行情 ${row['正股简称']}`
      }
      this.marketDialog.visible=true
    },

    // 下载数据
    async downloadFile(){
      this.$api.downloadFile().then(res=>{
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let objectUrl = URL.createObjectURL(blob)
        //创建a标签链接并点击 start
        let link = document.createElement('a')
        link.style.display = 'none';
        link.href = objectUrl
        link.download = `多因子数据.csv`
        document.body.appendChild(link);
        link.click();
        //创建a标签链接并点击 end
        document.body.removeChild(link)
        window.URL.revokeObjectURL(blob)
      })
    },

    // 自动购买数据
    async autoBuyDataFn(value){
      localStorage.setItem('autoBuyData', value)
      
      if (isLoginStatus() && value && this.allData[0] && isNaN(parseFloat(this.allData[0]['综合分数']))) {
        await this.$api.buyData()
        this.getData({ noBuy: true })
      }
    },

    // 获取热门板块
    getHotPlates({
      allPlates, // 板块数据
      allData
    }){
      if (!allData || !allData[0] || isNaN(parseFloat(allData[0]['综合分数']))) return '-'
      // 板块
      const blackList = [
        "AH股",
        "HS300_",
        "标准普尔", 
        "富时罗素",
        "广东板块",
        "沪股通", 
        "机构重仓", 
        "融资融券",
        "上证180_",
        "转债标的", 
        "浙江板块", 
        "创投", 
        "创业板综", 
        "IPO受益", 
        "西藏板块", 
        "甘肃板块", 
        "雄安新区", 
        "江苏板块", 
        "深成500",
        "深股通", 
        "中证500", 
        "山东板块", 
        "基金重仓", 
        "送转预期",
        "预盈预增", 
        "湖南板块", 
        "宁夏板块", 
        "重庆板块", 
        "昨日连板",
        "昨日连板_含一字",
        "昨日涨停",
        "昨日涨停_含一字", 
        "江西板块", 
        "深圳特区", 
        "参股新三板",
        "分拆预期", 
        "湖北板块", 
        "QFII重仓", 
        "MSCI中国", 
        "北京板块",
        "证金持股", 
        "安徽板块", 
        "京津冀",
        "天津板块", 
        "海南板块", 
        "股权激励", 
        "上证380", 
        "山西板块",
        "中药概念",
        "医疗器械概念",
        "电商概念"
      ]
      let plates={}
      allPlates.filter(p=>!blackList.includes(p)).forEach(p=>plates[p]=0)
    
      // 各表前二十
      let data={
        multiFactor: allData.sort((a, b)=>b['综合分数'] - a['综合分数']).slice(0, 20),
        multiFactor_v1_1: allData.sort((a, b)=>b['多因子V1.1'] - a['多因子V1.1']).slice(0, 20),
        fiveFactor: allData.sort((a, b)=>b['五因子分数'] - a['五因子分数']).slice(0, 20),
        dbLow: allData.sort((a, b)=>b['双低分数'] - a['双低分数']).slice(0, 20),
        power: allData.sort((a, b)=>b['强势分数'] - a['强势分数']).slice(0, 20),
      }
      
      // 计算热门板块
      let cache=[...data['multiFactor'], ...data['multiFactor_v1_1'], ...data['fiveFactor'], ...data['dbLow'], ...data['power']]
      cache=cache.reduce((acc, cur)=>{
        if (acc.every(r=>r['转债代码']!=cur['转债代码'])) acc.push(cur)
        return acc
      }, [])
      cache.forEach(r=>{
        Object.keys(plates).forEach(k=>{
          if (r['所属版块'] && r['所属版块'].includes(k)) plates[k]++
        })
      })
      console.log(plates, cache)
      // 返回热门板块
      return Object.keys(plates).sort((a, b)=>{
        return plates[b] - plates[a]
      }).slice(0, 6)
    },

    // 过滤出热门的数据
    setHotPlatesData(plateName){
      this.filterParams={
        '所属版块': [plateName],
        sort: 20
      }
    }
  },
  created() {
    this.$store.dispatch('getAllPlates')
    this.getData()
    this.getBondCollectList()
    // this.getBondList()
    this.getFilter()
  },
  watch: {
    height(){
      this.scrollFn()
    },
    filterParams:{
      handler(){
        this.savaFilter()
      },
      deep: true
    }
  },

  beforeUnmount() {
    clearTimeout(this.autoRefreshTimer)
  },
  deactivated() {
    clearTimeout(this.autoRefreshTimer)
  },
  activated() {
    this.autoRefreshFn(this.autoRefresh)
  }
})