
<template>
  <section class="filters">
    <div class="filter_left">  
      <div class="filter_item" v-for="item in configAfter" :key="item.label">
        <span class="item_title">{{ item.label }}</span>
        <!-- 输入框 -->
        <el-input 
          v-if="!item.type || item.type=='input'"
          style="width: 100px"
          size="mini"
          v-model="search[item.field]" 
        ></el-input>

        <!-- 下拉选择 -->
        <el-select 
          v-if="!item.type || item.type=='select'"
          filterable
          :multiple="item.multiple"
          :style="item.style || 'width: 100px'"
          size="mini"
          v-model="search[item.field]" 
        >
          <el-option
            v-for="it in item.list"
            :key="it.value"
            :label="it.label"
            :value="it.value">
          </el-option>
        </el-select>

        <!-- 范围 -->
        <div class="range" v-if="item.type=='range'">
          <el-input-number 
            style="width: 70px"
            size="mini"
            :controls="false"
            v-model="search[`${item.field}Min`]"
          ></el-input-number>
          -
          <el-input-number
            style="width: 70px"
            size="mini"
            :controls="false"
            v-model="search[`${item.field}Max`]"
          ></el-input-number>
        </div>

        <span class="unit">{{ item.unit }}</span>
      </div>
      <div class="filter_item">
        <el-button type="primary" size="mini" @click="filterFn">筛选</el-button>
        <el-button size="mini" @click="resetFn">重置</el-button>
        <el-button size="mini" @click="show">显/隐</el-button>
      </div>
    </div>
    <!-- <div class="filter_right">
      <el-button type="primary" size="mini">搜索</el-button>
      <el-button size="mini">重置</el-button>
    </div> -->
  </section>
</template>

<script>
export default {
  props: {
    // 配置
    config: {
      type: Array,
      default:()=>([])
    },
    // 默认的参数
    params: {
      type: Object,
      default: ()=>({})
    }
  },
  data () {
    return {
      search: {},
      more: false, // 显示更多
    };
  },

  components: {},

  computed: {
    configAfter(){
      if (!this.more) return this.config.slice(0, 5)
      return this.config 
    }
  },

  methods: {
    init(){
      Object.assign(this.search, this.params)
    },
    resetFn(){
      this.search={}
      this.filterFn()
    },
    filterFn(){
      this.$emit('filter', {...this.search})
    },
    show(){
      this.more=!this.more
      this.$emit('more')
    }
  },

  mounted() {
    this.init()
  },
  watch: {
    params: {
      handler(){
        this.init()
      },
      deep: true
    }
  }
}

</script>
<style lang='scss' scoped>
.filters{
  // display: flex;
  .filter_left{
    display: flex;
    flex-wrap: wrap;
    // justify-content: flex-start;
    .filter_item{
      // flex: 1;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      font-size: 14px;
      margin-top: 5px;

      .item_title{
        flex-shrink: 0;
        margin-right: 5px;
      }
      .unit{
        flex-shrink: 0;
        margin-left: 5px;
      }
      &:not(:last-child){
        margin-right: 10px;
      }
      &:last-child{
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
}
</style>